import React, { useRef } from 'react'
import styled from 'styled-components'

import {
  BtnPrimaryLarge,
  Form,
  FormContainer,
  InputBox,
  InputFieldModal,
  ModalLabel,
} from './CommonStylesForms'

const HeaderVerify = styled.div`
  color: #0069b4;
  font-size: 25px;
  font-family: 'Oxygen', sans-serif;
  font-weight: 700;
  text-align: left;
`
const HeaderText = styled.div`
  color: black;
  font-size: 15px;
  font-family: 'Oxygen', sans-serif;
  font-weight: 700;
  text-align: left;
`
const PromotionText = styled.div`
  color: black;
  font-size: 12px;
  font-family: 'Oxygen', sans-serif;
  font-weight: 700;
  text-align: left;
  margin: 0 5px;
  padding: 5px;
`
const BottomSection = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0 0 0;
  align-items: center;
`

function AccountForm(props) {
  const newForm = useRef()

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(newForm.current)
    props.setUserData({
      username: form.get('username'),
      given_names: form.get('given_names'),
      family_names: form.get('family_names'),
      country_of_citizenship: form.get('country_of_citizenship'),
      country_of_residence: form.get('country_of_residence'),
      phone_number: form.get('phone_number'),
      email: form.get('email'),
      password: form.get('password'),
      confirm_password: form.get('confirm_password'),
    })
    props.setPageState('create-account-issue')
    props.setLoader(false)
  }

  const accountDisplay = (
    <div>
      <FormContainer>
        <HeaderVerify>Tell us about yourself</HeaderVerify>
        <HeaderText>All fields are required unless otherwise noted</HeaderText>
        <Form id="form" onSubmit={handleSubmit} ref={newForm}>
          <InputBox>
            <ModalLabel>Username</ModalLabel>
            <InputFieldModal
              type="text"
              name="username"
              defaultValue={props.userData.username}
            ></InputFieldModal>
          </InputBox>
          <InputBox>
            <ModalLabel>Given name(s)</ModalLabel>
            <InputFieldModal
              type="text"
              name="given_names"
              defaultValue={props.userData.given_names}
            ></InputFieldModal>
          </InputBox>
          <InputBox>
            <ModalLabel>Family name(s)</ModalLabel>
            <InputFieldModal
              type="text"
              name="family_names"
              defaultValue={props.userData.family_names}
            ></InputFieldModal>
          </InputBox>
          <InputBox>
            <ModalLabel>Country of citizenship</ModalLabel>
            <InputFieldModal
              type="text"
              name="country_of_citizenship"
              defaultValue={props.userData.country_of_citizenship}
            ></InputFieldModal>
          </InputBox>
          <InputBox>
            <ModalLabel>Country of residence</ModalLabel>
            <InputFieldModal
              type="text"
              name="country_of_residence"
              defaultValue={props.userData.country_of_residence}
            ></InputFieldModal>
          </InputBox>
          <InputBox>
            <ModalLabel>Phone number</ModalLabel>
            <InputFieldModal
              type="text"
              name="phone_number"
              defaultValue={props.userData.phone_number}
            ></InputFieldModal>
          </InputBox>
          <InputBox>
            <ModalLabel>Email address</ModalLabel>
            <InputFieldModal
              type="text"
              name="email"
              defaultValue={props.userData.email}
            ></InputFieldModal>
          </InputBox>
          <InputBox>
            <ModalLabel>Password</ModalLabel>
            <InputFieldModal
              type="password"
              name="password"
              defaultValue={props.userData.password}
            ></InputFieldModal>
          </InputBox>
          <InputBox>
            <ModalLabel>Confirm Password</ModalLabel>
            <InputFieldModal
              type="password"
              name="confirm_password"
              defaultValue={props.userData.confirm_password}
            ></InputFieldModal>
          </InputBox>
          <BottomSection>
            <input type="checkbox" />
            <PromotionText>
              I would like to receive promotional emails and newsletters from
              SITA Air.
            </PromotionText>
            <BtnPrimaryLarge className="uppercase" type="submit">
              Continue
            </BtnPrimaryLarge>
          </BottomSection>
        </Form>
      </FormContainer>
    </div>
  )

  return <div>{accountDisplay}</div>
}

export default AccountForm
