import React, { useRef, useState, useEffect } from 'react'
import Axios from 'axios'
import QRCode from 'qrcode.react'
import styled from 'styled-components'
import BarCode from './assets/bar-code.png'

import { DateTime } from 'luxon'

import './App.css'
import AccountForm from './UI/AccountForm'

import iconArrowRight from './assets/arrow-right.svg'
import iconArrowUp from './assets/arrow-up.svg'
import iconArrowDown from './assets/arrow-down.svg'
import iconArrowsLeftRight from './assets/arrows-left-right.svg'
import iconAvatar from './assets/avatar.svg'
import iconAvatarWhite from './assets/avatar-white.svg'
import iconHelp from './assets/question-mark.svg'
import iconHelpWhite from './assets/question-mark-white.svg'
import iconLanguage from './assets/language.svg'
import iconLanguageWhite from './assets/language-white.svg'
import iconSearch from './assets/search.svg'
import iconSearchWhite from './assets/search-white.svg'
import iconVisa from './assets/visa.svg'

import locationMorocco from './assets/location-morocco.jpeg'
import locationLosAngeles from './assets/location-los-angeles.jpeg'
import locationDubai from './assets/location-dubai.jpg'
import locationNewYork from './assets/location-new-york.jpg'
import locationJordan from './assets/location-amman-jordan.jpg'

import bubbleImage1 from './assets/satellite-1-1.png'
import bubbleImage2 from './assets/satellite-1-2.png'
import bubbleImage3 from './assets/satellite-1-3.png'
import bubbleImage4 from './assets/satellite-2-1.png'

import logo from './assets/logo.png'
import logoWhite from './assets/logo-white.png'

import thumbnailFamilyTravel from './assets/article-family-travel.jpg'
import thumbnailWhenYouFly from './assets/article-when-you-fly.jpg'

import {
  Btn,
  BtnPrimaryLarge,
  BtnPrimaryLight,
  BtnDashboard,
  BtnSecondaryLarge,
  BtnSecondaryLightLarge,
  BtnTertiaryLightLarge,
  BtnTertiaryLightSmall,
  Bubble,
  FormContainer,
  FormWrapper,
  HeaderOptions,
  Icon,
  IconArrow,
  IconAvatar,
  IconHelp,
  IconLanguage,
  IconSearch,
  IconVisa,
  Logo,
  PoweredByLogo,
  Thumbnail,
} from './UI/CommonStylesForms'

const Frame = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`
const QR = styled(QRCode)`
  display: block;
  margin: auto;
  padding: 20px;
  background: #fff;
`
const BtnLogin = styled.div`
  float: left;
  margin-right: 13px;
`
const BtnJoin = styled.div`
  float: left;
  margin-right: 13px;
`
const Account = styled.div`
  float: left;
  margin-right: 13px;
`
const Copyright = styled.div`
  position: static;
  margin-bottom: 8px;
  width: 100%;
  text-align: center;

  @media (min-width: 540px) {
    position: absolute;
    bottom: 20px;
    left: 0;
  }
`

function Location(props) {
  return (
    <div
      className="trips-image"
      style={{
        backgroundImage: `url(${props.backgroundImage})`,
      }}
    >
      {props.children}
    </div>
  )
}

function Main(props) {
  let mainClasses = ''

  if (props.banner) mainClasses = props.banner

  if (props.footerMode && props.footerMode === 'dark')
    mainClasses += ' main-footer-dark'
  else mainClasses += ' main-footer-light'

  return <main className={mainClasses}>{props.children}</main>
}

function Header(props) {
  let headerClasses = ''
  let greeting = ''
  let accountActions = ''

  if (props.headerMode && props.headerMode === 'dark')
    headerClasses += ' header-dark'
  else headerClasses = ''

  if (props.connectionPurpose === 'login') {
    headerClasses += ' start-login'
  }

  if (props.userData && props.userData.given_names) {
    greeting += props.userData.given_names
  }
  if (props.userData && props.userData.family_names) {
    greeting += ' ' + props.userData.family_names
  }

  if (props.loggedIn === false) {
    accountActions = (
      <>
        <BtnJoin>
          <Btn
            className={
              props.headerMode === 'dark'
                ? 'btn-background-dark'
                : 'btn-background-light'
            }
            onClick={props.startNewAccount}
          >
            Join
          </Btn>
        </BtnJoin>
        <BtnLogin>
          <Btn
            className={
              props.headerMode === 'dark'
                ? 'btn-background-dark'
                : 'btn-background-light'
            }
            onClick={props.startLogin}
          >
            Log In
          </Btn>
        </BtnLogin>
        <div className="overlay"></div>
        {!props.loader ? (
          <div className="dialog-login">
            <span
              className="dialog-close"
              onClick={() => props.setConnectionPurpose('')}
            >
              X
            </span>
            <h3 className="text-secondary text-center">Log into SITA Air</h3>
            <div className="input-holder">
              <input
                className="single-line"
                type="text"
                placeholder="SITA Air ID or Username"
              ></input>
            </div>
            <div className="input-holder">
              <input
                className="single-line password"
                type="password"
                placeholder="Password"
              ></input>
            </div>
            <input type="checkbox" /> Remember Me
            <BtnSecondaryLarge className="full-width margin-top uppercase">
              Log In
            </BtnSecondaryLarge>
            <p>
              <a>Forgot password</a>
            </p>
            <div className="qr-holder">
              <h3 className="text-primary">
                Log in with a<br />
                SITA Air ID
              </h3>
              <QR
                value={props.invitation ? props.invitation.invitation_url : ''}
                size={260}
                renderAs="svg"
              />
              <p>
                Scan the above QR code
                <br />
                with your app.
              </p>
            </div>
          </div>
        ) : (
          <div className="dialog-login">
            <span
              className="dialog-close"
              onClick={() => props.setConnectionPurpose('')}
            >
              X
            </span>
            <div className="loader-wrapper">
              <div className="loader"></div>
              <h3 className="text-secondary text-bold">Please wait...</h3>
            </div>
          </div>
        )}
      </>
    )
  } else {
    props.headerMode === 'dark'
      ? (accountActions = (
          <Account>
            <IconAvatar src={iconAvatarWhite} alt="Account" /> {greeting} ▾
          </Account>
        ))
      : (accountActions = (
          <Account>
            <IconAvatar src={iconAvatar} alt="Account" /> {greeting} ▾
          </Account>
        ))
  }

  return (
    <div className={'header' + headerClasses}>
      {props.headerMode === 'dark' ? (
        <Logo
          onClick={() => props.setPageState('home')}
          src={logoWhite}
          alt="SITA Air"
        />
      ) : (
        <Logo
          onClick={() => props.setPageState('home')}
          src={logo}
          alt="SITA Air"
        />
      )}

      {props.headerMode === 'dark' ? (
        <HeaderOptions className="text-light">
          <IconSearch src={iconSearchWhite} alt="Search" />
          <IconLanguage src={iconLanguageWhite} alt="Language" /> US | English
          {accountActions}
        </HeaderOptions>
      ) : (
        <>
          <HeaderOptions className="text-secondary">
            <IconSearch src={iconSearch} alt="Search" />
            <IconLanguage src={iconLanguage} alt="Language" /> US | English
            {accountActions}
          </HeaderOptions>
          <Bubble className="bubble-3" src={bubbleImage3} alt="" />
          <Bubble className="bubble-4" src={bubbleImage4} alt="" />
        </>
      )}
      <menu>
        <ul>
          <li>
            <a href="#">Explore</a>
          </li>
          <li>
            <a href="#">Plan and Book</a>
          </li>
          <li>
            <a href="#">Deals and Promos</a>
          </li>
          <li>
            <a href="#">Before You Fly</a>
          </li>
          <li>
            <a href="#">Need Help?</a>
          </li>
        </ul>
      </menu>
    </div>
  )
}

function Footer(props) {
  let footerClasses = ''

  if (props.footerMode && props.footerMode === 'dark')
    footerClasses += ' footer-dark'
  else footerClasses += ' footer-light'

  return (
    <div className={'footer' + footerClasses}>
      {props.footerMode && props.footerMode === 'dark' ? (
        <Copyright className="text-light">
          Copyright © SITA. All rights reserved.
        </Copyright>
      ) : (
        <Copyright>Copyright © SITA. All rights reserved.</Copyright>
      )}
      {props.footerMode === 'dark' ? (
        <>
          <Bubble className="bubble-1" src={bubbleImage1} alt="" />
          <Bubble className="bubble-2" src={bubbleImage4} alt="" />
          <PoweredByLogo src={logoWhite} alt="SITA Air" />
        </>
      ) : (
        <PoweredByLogo src={logo} alt="SITA Air" />
      )}
    </div>
  )
}

function sleep(ms) {
  return new Promise((resolveFunc) => setTimeout(resolveFunc, ms))
}

function App(props) {
  // Page State controls which screen is displayed on the site
  // Valid values:
  /*
    [
      home,
      create-account-form,
      create-account-issue,
      create-account-success,
      error-logged-out,
      account-dashboard,
      boarding-pass-processing-credential,
      boarding-pass-review,
      boarding-pass-success,
      error-logged-in,
    ]
  */
  const [pageState, setPageState] = useState('home')

  const [invitation, setInvitation] = useState({})
  const [connectionActive, setConnectionActive] = useState(false)
  const [connectionPurpose, setConnectionPurpose] = useState('')
  const [checked, setChecked] = useState([])
  const effectRan = useRef()

  const [loader, setLoader] = useState(false)

  const [verification, setVerification] = useState({})

  const [userData, setUserData] = useState({})
  const [dtcData, setDTCData] = useState({})
  const [selectedTripData, setSelectedTripData] = useState()

  const tripDataSet = [
    {
      background_image: locationMorocco, // Note, this is not a usual boarding pass field!
      airline_alliance: 'SITA Air Alliance',
      passenger_tsa_precheck: 'Yes',
      pnr: '43119',
      ticket_eticket_number: '840281123',
      ticket_designated_carrier: 'SITA Air',
      ticket_operating_carrier: 'SITA Air',
      ticket_flight_number: 'JS42',
      ticket_class: 'Economy',
      ticket_seat_number: '4A',
      ticket_exit_row: 'No',
      ticket_origin: 'JFK',
      ticket_origin_city: 'New York City', // Note, this is not a usual boarding pass field!
      ticket_destination: 'CMN',
      ticket_destination_city: 'Morocco', // Note, this is not a usual boarding pass field!
      ticket_special_service_request: 'None',
      ticket_with_infant: 'Yes',
      ticket_luggage: '2pc',
      boarding_gate: 'Z32',
      boarding_zone_group: '4',
      boarding_secondary_screening: 'No',
      boarding_date_time: '2023-09-28T11:00',
      boarding_departure_date_time: '2023-09-28T23:00',
      boarding_arrival_date_time: '2023-09-29T21:33',
      frequent_flyer_airline: 'SITA Air',
      frequent_flyer_number: '14524',
      frequent_flyer_status: 'Bronze',
      standby_status: 'Revenue',
      standby_boarding_date: '2023-09-28T02:10',
      standby_priority: 'Space Available 1',
      sequence_number: '2',
    },
    {
      background_image: locationLosAngeles, // Note, this is not a usual boarding pass field!
      airline_alliance: 'SITA Air Alliance',
      passenger_tsa_precheck: 'Yes',
      pnr: '12112',
      ticket_eticket_number: '848842113',
      ticket_designated_carrier: 'SITA Air',
      ticket_operating_carrier: 'SITA Air',
      ticket_flight_number: 'B4212',
      ticket_class: 'First',
      ticket_seat_number: '44C',
      ticket_exit_row: 'Yes',
      ticket_origin: 'CMN',
      ticket_origin_city: 'Morocco', // Note, this is not a usual boarding pass field!
      ticket_destination: 'LAX',
      ticket_destination_city: 'Los Angeles', // Note, this is not a usual boarding pass field!
      ticket_special_service_request: 'None',
      ticket_with_infant: 'Yes',
      ticket_luggage: '2pc',
      boarding_gate: 'F21',
      boarding_zone_group: '1',
      boarding_secondary_screening: 'No',
      boarding_date_time: '2023-12-03T11:00',
      boarding_departure_date_time: '2023-06-01T22:30',
      boarding_arrival_date_time: '2023-10-01T22:04',
      frequent_flyer_airline: 'SITA Air',
      frequent_flyer_number: '12311',
      frequent_flyer_status: 'Silver',
      standby_status: 'Revenue',
      standby_boarding_date: '2023-09-16T08:30',
      standby_priority: 'Space Available 1',
      sequence_number: '5',
    },
    {
      background_image: locationDubai, // Note, this is not a usual boarding pass field!
      airline_alliance: 'SITA Air Alliance',
      passenger_tsa_precheck: 'Yes',
      pnr: '55589',
      ticket_eticket_number: '109420123',
      ticket_designated_carrier: 'SITA Air',
      ticket_operating_carrier: 'SITA Air',
      ticket_flight_number: 'I1152',
      ticket_class: 'First',
      ticket_seat_number: '15B',
      ticket_exit_row: 'No',
      ticket_origin: 'LAX',
      ticket_origin_city: 'Los Angeles', // Note, this is not a usual boarding pass field!
      ticket_destination: 'DXB',
      ticket_destination_city: 'Dubai', // Note, this is not a usual boarding pass field!
      ticket_special_service_request: 'None',
      ticket_with_infant: 'Yes',
      ticket_luggage: '2pc',
      boarding_gate: 'A54',
      boarding_zone_group: '1',
      boarding_secondary_screening: 'No',
      boarding_date_time: '2023-09-15T10:00',
      boarding_departure_date_time: '2023-09-15T10:30',
      boarding_arrival_date_time: '2023-09-15T17:52',
      frequent_flyer_airline: 'SITA Air',
      frequent_flyer_number: '14524',
      frequent_flyer_status: 'Gold',
      standby_status: 'Revenue',
      standby_boarding_date: '2023-09-16T08:30',
      standby_priority: 'Space Available 1',
      sequence_number: '5',
    },
    {
      background_image: locationNewYork, // Note, this is not a usual boarding pass field!
      airline_alliance: 'SITA Air Alliance',
      passenger_tsa_precheck: 'Yes',
      pnr: '77566',
      ticket_eticket_number: '7240182',
      ticket_designated_carrier: 'SITA Air',
      ticket_operating_carrier: 'SITA Air',
      ticket_flight_number: 'XS155',
      ticket_class: 'Economy',
      ticket_seat_number: '55A',
      ticket_exit_row: 'No',
      ticket_origin: 'DXB',
      ticket_origin_city: 'Dubai', // Note, this is not a usual boarding pass field!
      ticket_destination: 'JFK',
      ticket_destination_city: 'New York City', // Note, this is not a usual boarding pass field!
      ticket_special_service_request: 'None',
      ticket_with_infant: 'Yes',
      ticket_luggage: '2pc',
      boarding_gate: 'A14',
      boarding_zone_group: '3',
      boarding_secondary_screening: 'Yes',
      boarding_date_time: '2022-09-18T08:30',
      boarding_departure_date_time: '2023-09-18T09:00',
      boarding_arrival_date_time: '2023-09-18T23:08',
      frequent_flyer_airline: 'SITA Air',
      frequent_flyer_number: '99482',
      frequent_flyer_status: 'Bronze',
      standby_status: 'Non-Revenue',
      standby_boarding_date: '2023-09-19T09:30',
      standby_priority: 'Space Available 7',
      sequence_number: '3',
    },
    {
      background_image: locationJordan, // Note, this is not a usual boarding pass field!
      airline_alliance: 'SITA Air Alliance',
      passenger_tsa_precheck: 'Yes',
      pnr: '7778',
      ticket_eticket_number: '482102',
      ticket_designated_carrier: 'SITA Air',
      ticket_operating_carrier: 'SITA Air',
      ticket_flight_number: 'OZ453',
      ticket_class: 'Business',
      ticket_seat_number: '4A',
      ticket_exit_row: 'No',
      ticket_origin: 'JFK',
      ticket_origin_city: 'New York City', // Note, this is not a usual boarding pass field!
      ticket_destination: 'AMM',
      ticket_destination_city: 'Amman', // Note, this is not a usual boarding pass field!
      ticket_special_service_request: 'None',
      ticket_with_infant: 'No',
      ticket_luggage: '2pc',
      boarding_gate: 'M23',
      boarding_zone_group: '2',
      boarding_secondary_screening: 'Yes',
      boarding_date_time: '2023-10-11T12:39',
      boarding_departure_date_time: '2023-10-11T13:11',
      boarding_arrival_date_time: '2023-10-12T00:43',
      frequent_flyer_airline: 'SITA Air',
      frequent_flyer_number: '11321',
      frequent_flyer_status: 'Silver',
      standby_status: 'Non-Revenue',
      standby_boarding_date: '2023-10-13T10:30',
      standby_priority: 'Space Available 7',
      sequence_number: '4',
    },
  ]

  const requestInvitation = () => {
    if (Object.keys(invitation).length === 0) {
      Axios({
        method: 'POST',
        url: `/api/invitations`,
      })
        .then((res) => {
          console.log('Invitation:', res.data)
          setInvitation(res.data)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  useEffect(() => {
    //(AmmonBurgi) Prevent from triggering twice in development. See React strict mode.
    if (process.env.NODE_ENV === 'development') {
      if (effectRan.current === true) {
        requestInvitation()
      }

      return () => {
        effectRan.current = true
      }
    } else {
      requestInvitation()
    }
  }, [invitation])

  useEffect(() => {
    async function issueAccountCredential() {
      if (invitation.connection_id && !connectionActive) {
        Axios({
          method: 'GET',
          url: `/api/connections/${invitation.connection_id}`,
          timeout: 1000 * 60 * 63,
        })
          .then(() => {
            console.log('Connection confirmed!')
            setConnectionActive(true)
            setLoader(true)
          })
          .catch((error) => {
            console.log(error)
          })
      }
      if (
        invitation.connection_id &&
        connectionActive &&
        connectionPurpose === 'login'
      ) {
        // Request presentation of the Air ID credential
        Axios({
          method: 'POST',
          url: `/api/verifications`,
          data: {
            connection_id: invitation.connection_id,
            contact_id: invitation.contact_id,
            invitation_id: invitation.invitation_id,
            workflow: 'Air_ID',
          },
          timeout: 1000 * 60 * 63,
          // signal: verController.signal,
        })
          .then((verRes) => {
            const verificationRecords = verRes.data.verificationRecords

            //console.log('Verification:', verRes)

            let verifiedAttributes = {}
            verificationRecords.forEach((record) => {
              record.result_data.forEach((attributes, index) => {
                verifiedAttributes[attributes.name] = attributes.value
              })
            })

            // setVerification({
            //   connectionId: verificationRecords[0].connection_id,
            //   verifiedAttributes: verifiedAttributes,
            // })

            // Now that we have the presentation, set the userData and do some housekeeping
            setUserData({
              accound_id: verifiedAttributes['account_id'],
              account_established_date:
                verifiedAttributes['account_established_date'],
              username: verifiedAttributes['username'],
              email: verifiedAttributes['email'],
              phone_number: verifiedAttributes['phone_number'],
              given_names: verifiedAttributes['given_names'],
              family_names: verifiedAttributes['family_names'],
              country_of_citizenship: verifiedAttributes['country_of_citizenship'],
              country_of_residence: verifiedAttributes['country_of_residence'],
              airline_name: verifiedAttributes['airline_name'],
              frequent_flyer_number:
                verifiedAttributes['frequent_flyer_number'],
              credential_issued_date:
                verifiedAttributes['credential_issued_date'],
            })

            setConnectionPurpose('')
            setLoader(false)
            setPageState('account-dashboard')
          })
          .catch((error) => {
            console.error('Error: ', error.response.data.message)
          })
      }
      if (
        invitation.connection_id &&
        connectionActive &&
        connectionPurpose === 'new-account'
      ) {
        // Issue an Air ID credential
        const accountID = parseInt(Math.random() * 1000000000, 10).toString()
        let accountEstablisheDate = Math.floor(Date.now() / 1000)
        accountEstablisheDate = accountEstablisheDate.toString()

        // Update the userData to include the new values
        setUserData({
          ...userData,
          accound_id: accountID,
          account_established_date: accountEstablisheDate,
          airline_name: 'SITA Air',
          frequent_flyer_number: accountID,
        })

        // Set up the credentialData for issuance
        const credentialData = {
          account_id: accountID,
          account_established_date: accountEstablisheDate,
          username: userData.username,
          email: userData.email,
          phone_number: userData.phone_number,
          country_of_citizenship: userData.country_of_citizenship,
          country_of_residence: userData.country_of_residence,
          given_names: userData.given_names,
          family_names: userData.family_names,
          airline_name: 'SITA Air',
          frequent_flyer_number: accountID,
          credential_issued_date: accountEstablisheDate,
        }

        Axios({
          method: 'POST',
          url: `/api/credentials/`,
          data: {
            invitation_id: invitation.invitation_id,
            contact_id: '',
            schemaName: 'Air_ID',
            credentialData: credentialData,
          },
        })
          .then(() => {
            async function createAccountSuccess() {
              await sleep(10000)
              setLoader(false)
              setPageState('create-account-success')
              await sleep(5000)
              setPageState('account-dashboard')
            }
            createAccountSuccess()
          })
          .catch((error) => {
            console.log(error)
            setPageState('error-logged-out')
          })
      }
    }

    issueAccountCredential()
  }, [invitation, connectionActive])

  function startNewAccount() {
    setConnectionPurpose('new-account')
    setPageState('create-account-form')
  }

  function startLogin() {
    setConnectionPurpose('login')
  }

  function handleChecked(e, pnr) {
    if (e && e.target) {
      setChecked({ ...checked, [pnr]: e.target.checked })
    }
  }

  function getBoardingPass(tripData) {
    const seamless = checked[tripData.pnr] === undefined || checked[tripData.pnr] ? true : false
    const requireDTA = tripData.background_image === locationDubai ||
    tripData.background_image === locationMorocco ? true : false
    
    let workflow = ''
    if (!seamless) {
      workflow = "dtc_type1_identity_nonseamless"
    } else if (seamless) {
      workflow = "dtc_type1_identity_seamless"
    }

    console.log('Workflow:', workflow)

    setLoader(true)
    setSelectedTripData(tripData)
    Axios({
      method: 'POST',
      url: `/api/verifications`,
      data: {
        connection_id: invitation.connection_id,
        contact_id: invitation.contact_id,
        invitation_id: invitation.invitation_id,
        workflow: workflow,
        dta: requireDTA
      },
      timeout: 1000 * 60 * 63,
      // signal: verController.signal,
    })
      .then((verRes) => {
        const verificationRecords = verRes.data.verificationRecords

        // console.log('Verification:', verRes)

        let chipPhoto = ''

        let verifiedAttributes = {}
        verificationRecords.forEach((record) => {
          record.result_data.forEach((attributes, index) => {
            verifiedAttributes[attributes.name] = attributes.value
          })
        })

        // (AmmonBurgi) If needed, format the base64 to be compatible with an html img element
        if (verifiedAttributes['chip-photo']) {
          const imageParts = verifiedAttributes['chip-photo'].split(',')
          if (imageParts[0] !== 'data:image/jpeg;base64') {
            chipPhoto =
              'data:image/jpeg;base64,' + verifiedAttributes['chip-photo']
          } else {
            chipPhoto = verifiedAttributes['chip-photo']
          }
        }

        // Now that we have the presentation, set the dtcData and do some housekeeping
        setDTCData({
          given_names: verifiedAttributes['given-names'],
          family_name: verifiedAttributes['family-name'],
          chip_photo: verifiedAttributes['chip-photo'] ? verifiedAttributes['chip-photo'] : '',
          chip_photo_html: chipPhoto,
          date_of_birth: verifiedAttributes['date-of-birth'],
          gender: verifiedAttributes['gender'],
          nationality: verifiedAttributes['nationality'],
          dtc: verifiedAttributes['dtc'],
          upk: verifiedAttributes['upk'],
          document_number: verifiedAttributes['document-number'],
          document_type: verifiedAttributes['document-type'],
          issuing_authority: verifiedAttributes['issuing-authority'],
          issuing_state: verifiedAttributes['issuing-state'],
          issue_date: verifiedAttributes['issue-date'],
          expiry_date: verifiedAttributes['expiry-date'],
          created_date: verifiedAttributes['created-date'],
        })

        // setVerification({
        //   connectionId: verificationRecords[0].connection_id,
        //   verifiedAttributes: verifiedAttributes,
        // })
        setLoader(false)
        setPageState('boarding-pass-processing-credential')
        setTimeout(() => setPageState('boarding-pass-review'), 4000)
      })
      .catch((error) => {
        setLoader(false)
        console.error('Error: ', error.response.data.message)
      })
  }

  function downloadBoardingPass() {
    const credentialData = {
      frequent_flyer_number: userData.frequent_flyer_number,
      frequent_flyer_airline: selectedTripData.frequent_flyer_airline,
      frequent_flyer_status: selectedTripData.frequent_flyer_status,
      airline_alliance: selectedTripData.airline_alliance,

      passenger_given_names: dtcData.given_names,
      passenger_family_names: dtcData.family_name,
      passenger_image: dtcData.chip_photo,
      passenger_tsa_precheck: selectedTripData.passenger_tsa_precheck,
      pnr: selectedTripData.pnr,
      sequence_number: selectedTripData.sequence_number,
      ticket_origin: selectedTripData.ticket_origin,
      ticket_destination: selectedTripData.ticket_destination,
      ticket_operating_carrier: selectedTripData.ticket_operating_carrier,
      ticket_designated_carrier: selectedTripData.ticket_designated_carrier,
      ticket_flight_number: selectedTripData.ticket_flight_number,
      ticket_eticket_number: selectedTripData.ticket_eticket_number,
      ticket_seat_number: selectedTripData.ticket_seat_number,
      ticket_class: selectedTripData.ticket_class,
      ticket_exit_row: selectedTripData.ticket_exit_row,
      ticket_special_service_request:
        selectedTripData.ticket_special_service_request,
      ticket_with_infant: selectedTripData.ticket_with_infant,
      ticket_luggage: selectedTripData.ticket_luggage,

      boarding_secondary_screening:
        selectedTripData.boarding_secondary_screening,
      boarding_date_time: Math.round(
        DateTime.fromISO(selectedTripData.boarding_date_time).ts / 1000
      ).toString(),
      boarding_departure_date_time: Math.round(
        DateTime.fromISO(selectedTripData.boarding_departure_date_time).ts /
          1000
      ).toString(),
      boarding_arrival_date_time: Math.round(
        DateTime.fromISO(selectedTripData.boarding_arrival_date_time).ts / 1000
      ).toString(),
      boarding_gate: selectedTripData.boarding_gate,
      boarding_zone_group: selectedTripData.boarding_zone_group,

      standby_status: selectedTripData.standby_status,
      standby_priority: selectedTripData.standby_priority,
      standby_boarding_date: Math.round(
        DateTime.fromISO(selectedTripData.standby_boarding_date).ts / 1000
      ).toString(),
    }

    Axios({
      method: 'POST',
      url: `/api/credentials/`,
      data: {
        invitation_id: invitation.invitation_id,
        contact_id: '',
        schemaName: 'Boarding_Pass',
        credentialData: credentialData,
      },
    })
      .then(() => {
        async function boardingPassSuccess() {
          await sleep(5000)
          setPageState('boarding-pass-success')
          setSelectedTripData()
          setChecked([])
        }
        boardingPassSuccess()
      })
      .catch((error) => {
        console.log(error)
        setPageState('error-logged-in')
        setChecked([])
      })
  }

  const pageHomePage = (
    <Main banner="banner-plane" footerMode="light">
      <Header
        setPageState={setPageState}
        startNewAccount={startNewAccount}
        startLogin={startLogin}
        invitation={invitation}
        connectionPurpose={connectionPurpose}
        setConnectionPurpose={setConnectionPurpose}
        loader={loader}
        headerMode="dark"
        loggedIn={false}
      />
      <div className="banner-row">
        <h1>Top 10 Trending Destinations</h1>
        <h2>Find your next great adventure</h2>
        <BtnTertiaryLightLarge>Learn More</BtnTertiaryLightLarge>
        <Bubble className="bubble-5" src={bubbleImage3} alt="" />
        <Bubble className="bubble-6" src={bubbleImage4} alt="" />
        <Bubble className="bubble-7" src={bubbleImage1} alt="" />
        <Bubble className="bubble-8" src={bubbleImage4} alt="" />
      </div>

      <div className="work-box">
        <div className="header-row">
          <span className="work-option text-secondary">Book a trip</span>
          <span className="work-option">Manage your trips</span>
          <span className="work-option">Check-in</span>
          <span className="work-option">Flight status</span>
          <div className="selectors">
            <BtnTertiaryLightSmall>Hotels</BtnTertiaryLightSmall>
            <BtnTertiaryLightSmall>Cars</BtnTertiaryLightSmall>
            <BtnTertiaryLightSmall>Packages</BtnTertiaryLightSmall>
            <IconHelp src={iconHelp} alt="" /> Help
          </div>
        </div>
        <div className="options-row">
          <div className="filters">
            <span className="filter">
              <input type="checkbox" /> Round Trip
            </span>
            <span className="filter">
              <input type="checkbox" /> One Way
            </span>
            <span className="filter">
              <input type="checkbox" /> Book with Miles
            </span>
            <span className="filter">
              <input type="checkbox" /> Flexible Dates
            </span>
            <span className="advanced-search">
              <a>Advanced Search (Multi-City, Certificates, Upgrades) ▶</a>
            </span>
          </div>
          <div className="locations">
            <div className="input-wrapper">
              <label>From</label>
              <input type="text" placeholder="New York City (JFK)" />
            </div>
            <IconArrow src={iconArrowsLeftRight} alt="" />
            <div className="input-wrapper">
              <label>To</label>
              <input type="text" placeholder="Dubai (DXB)" />
            </div>
          </div>
          <div className="dates">
            <div className="input-wrapper">
              <label>Departing</label>
              <input type="date" />
            </div>
            <IconArrow src={iconArrowRight} alt="" />
            <div className="input-wrapper">
              <label>Returning</label>
              <input type="date" />
            </div>
          </div>
          <div className="details">
            <div className="input-wrapper narrow">
              <label>Passenger(s)</label>
              <input type="text" size={3} defaultValue={1} />
            </div>
            <div className="input-wrapper narrow">
              <label>Class</label>
              <input type="text" size={9} placeholder="Comfort" />
            </div>
            <BtnSecondaryLarge className="float-right margin-bottom uppercase">
              Find Flights
            </BtnSecondaryLarge>
          </div>
        </div>
      </div>
      <div className="articles-box">
        <div className="article-primary">
          <h2>Enhance your trip with the app</h2>
          <p>
            Book trips fast, get special promotions, enjoy in-flight
            entertainment and more with the SITA Air app.
          </p>
          <BtnTertiaryLightLarge>Get the app</BtnTertiaryLightLarge>
        </div>
        <div className="article-secondary">
          <Thumbnail src={thumbnailWhenYouFly} alt="When you fly" />
          <h2>What to expect when you fly</h2>
          <p>
            The airline experience is rapidly changing every day. Come prepared
            and stress-free with the help of our useful guide on how to navigate
            your traveling experience in a post-Covid world.
          </p>
          <BtnSecondaryLightLarge>Learn more</BtnSecondaryLightLarge>
        </div>
        <div className="article-secondary">
          <Thumbnail src={thumbnailFamilyTravel} alt="Family travel" />
          <h2>Family bundles available</h2>
          <p>
            Plan an unforgettable adventure for the whole family. Our
            comprehensive family travel packages offer flights, hotels, car
            rentals and more at affordable rates.
          </p>
          <BtnSecondaryLightLarge>See deals</BtnSecondaryLightLarge>
        </div>
      </div>
      <Footer footerMode="light" />
    </Main>
  )

  const pageCreateAccountForm = (
    <Main footerMode="dark">
      <Header
        setPageState={setPageState}
        startNewAccount={startNewAccount}
        startLogin={startLogin}
        invitation={invitation}
        connectionPurpose={connectionPurpose}
        setConnectionPurpose={setConnectionPurpose}
        loader={loader}
        loggedIn={false}
      />
      <div className="account-row">
        <h1>Create an account</h1>
        <p className="subtitle-text">
          Begin your journey by creating a free SITA Air account and enjoy trip
          tracking, quicker check-in, bonus miles and more.
        </p>
        <p className="subtitle-text">
          Already have an account? <a onClick={() => startLogin()}>Log in</a>
        </p>
        <AccountForm
          setPageState={setPageState}
          userData={userData}
          setUserData={setUserData}
          setLoader={setLoader}
        />
      </div>
      <Footer footerMode="dark" />
    </Main>
  )

  const pageCreateAccountIssue = (
    <Main footerMode="dark">
      <Header
        setPageState={setPageState}
        startNewAccount={startNewAccount}
        startLogin={startLogin}
        invitation={invitation}
        connectionPurpose={connectionPurpose}
        setConnectionPurpose={setConnectionPurpose}
        loader={loader}
        loggedIn={false}
      />

      <div className="account-row">
        <h1>Welcome to SITA Air</h1>
        <FormContainer>
          <FormWrapper>
            {!loader ? (
              <>
                <p className="text-primary text-bold">
                  Send to your SITA Air digital wallet
                </p>
                <QR
                  value={invitation.invitation_url}
                  size={320}
                  renderAs="svg"
                />
                <p className="margin-top">
                  Scan the above QR code with your app to begin.
                </p>
                <BtnSecondaryLightLarge
                  onClick={() => setPageState('account-dashboard')}
                >
                  Skip this step
                </BtnSecondaryLightLarge>
                <p className="text-secondary text-spacing">
                  You can receive a sign-in credential at any time
                </p>
              </>
            ) : (
              <div className="loader-wrapper">
                <div className="loader"></div>
                <h3 className="text-secondary text-bold">Please wait...</h3>
              </div>
            )}
          </FormWrapper>
        </FormContainer>
      </div>
      <Footer footerMode="dark" />
    </Main>
  )

  const pageCreateAccountSuccess = (
    <Main footerMode="dark">
      <Header setPageState={setPageState} userData={userData} loggedIn={true} />
      <div className="content-row">
        <h1 className="title-text">
          Your account has been successfully created
        </h1>
        <p className="subtitle-text">
          Redirecting you to your account dashboard...
        </p>
        <BtnPrimaryLarge onClick={() => setPageState('account-dashboard')}>
          Go to Dashboard
        </BtnPrimaryLarge>
      </div>
      <Footer footerMode="dark" />
    </Main>
  )

  const pageErrorLoggedOut = (
    <Main footerMode="dark">
      <Header
        setPageState={setPageState}
        startNewAccount={startNewAccount}
        headerMode="light"
        loggedIn={false}
      />
      <div className="content-row">
        <h1 className="title-text">Something went wrong</h1>
        <p className="subtitle-text">
          An error has occurred. Please try again.
        </p>
        <BtnPrimaryLarge onClick={() => setPageState('home')}>
          Try again
        </BtnPrimaryLarge>
      </div>
      <Footer footerMode="dark" />
    </Main>
  )

  const pageAccountDashboard = (
    <Main banner="banner-blue" footerMode="light">
      <Header
        setPageState={setPageState}
        userData={userData}
        headerMode="dark"
        loggedIn={true}
      />
      <div className="dashboard-title-row">
        <div className="dashboard-left-title">
          <h1>
            Hi, {userData.given_names ? ' ' + userData.given_names : ''}
            {userData.family_names ? ' ' + userData.family_names : ''}
          </h1>
          <h3>
            {userData.account_established_date
              ? 'Joined on ' +
                DateTime.fromSeconds(
                  parseInt(userData.account_established_date)
                ).toFormat('MM/dd/yyyy')
              : ''}
          </h3>
        </div>
        <div className="dashboard-right-title">
          <p className="bar-right-text">SITA AIR ID</p>
          <p className="bar-text">
            {userData.accound_id ? '#' + userData.accound_id : ''}
          </p>
          <p className="bar-right-text">SITA Air Status</p>
          <p className="bar-text uppercase">Gold</p>
          <p className="bar-right-text miles-title-text">Miles</p>
          <p className="bar-text miles-text">150,000</p>
        </div>
        <Bubble className="bubble-9" src={bubbleImage2} alt="" />
        <Bubble className="bubble-10" src={bubbleImage4} alt="" />
      </div>
      <div className="dashboard-row">
        <div className="trips-dashboard">
          <h2 className="account-header">Manage Trips</h2>
          {tripDataSet.map((trip) => (
            <div key={trip.pnr} className="trips-banner">
              {selectedTripData && trip.pnr === selectedTripData.pnr ? (
                <>
                  <Location backgroundImage={trip.background_image}></Location>
                  {trip.background_image === locationDubai ||
                  trip.background_image === locationMorocco ? (
                    <div className="visa-wrapper">
                      <IconVisa src={iconVisa} alt="" />
                      <span>Visa Required</span>
                    </div>
                  ) : (
                    <></>
                  )}
                  <p className="trips-code-entry bar-right-text">
                    {trip.ticket_destination}
                  </p>
                  <p className="trips-city-entry bar-text">
                    {trip.ticket_destination_city}
                  </p>
                  <div className="loader-wrapper-dashboard">
                    <div className="loader-dashboard"></div>
                    <h3 className="text-light trips-text text-bold text-margin">
                      Please wait...
                    </h3>
                  </div>
                </>
              ) : (
                <>
                  <Location backgroundImage={trip.background_image}></Location>
                  {trip.background_image === locationDubai ||
                  trip.background_image === locationMorocco ? (
                    <div className="visa-wrapper">
                      <IconVisa src={iconVisa} alt="" />
                      <span>Visa Required</span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {console.log(trip.background_image)}
                  <p className="trips-code-entry bar-right-text">
                    {trip.ticket_destination}
                  </p>
                  <p className="trips-city-entry bar-text">
                    {trip.ticket_destination_city}
                  </p>
                  <p className="trips-date trips-text">Date</p>
                  <p className="trips-date-entry bar-right-text">
                    {DateTime.fromISO(trip.boarding_date_time).toFormat(
                      'MMM dd'
                    )}
                  </p>
                  <p className="trips-boarding trips-text">Boarding</p>
                  <p className="trips-boarding-entry bar-right-text">
                    {trip.boarding_date_time.split('T')[1]}
                  </p>
                  <p className="trips-seat trips-text">Seat</p>
                  <p className="trips-seat-entry bar-right-text">
                    {trip.ticket_seat_number}
                  </p>
                  <p className="trips-flight trips-text">Flight</p>
                  <p className="trips-flight-entry bar-right-text">
                    {trip.ticket_flight_number}
                  </p>
                  <p className="trips-departing trips-text">Departing</p>
                  <p className="trips-departing-entry bar-right-text">
                    {trip.boarding_departure_date_time.split('T')[1]}
                  </p>
                  <p className="trips-class trips-text">Class</p>
                  <p className="trips-class-entry bar-right-text">
                    {trip.ticket_class}
                  </p>

                  <BtnPrimaryLight
                    className="boarding-btn"
                    onClick={() => getBoardingPass(trip)}
                  >
                    Get Boarding Pass
                  </BtnPrimaryLight>
                  <BtnDashboard className="edit-btn">Edit Trip</BtnDashboard>
                  <BtnDashboard className="cancel-btn">
                    Cancel Trip
                  </BtnDashboard>
                  <p className="seamless trips-text">
                    <input
                      type="checkbox"
                      onChange={(e) => handleChecked(e, trip.pnr)}
                      defaultChecked
                    />{' '}
                    Enjoy a seamless journey{' '}
                    <Icon
                      src={iconHelpWhite}
                      alt="Help"
                      title="You consent to share your biometrics to board using your face as your passport (where available)"
                    />
                  </p>
                </>
              )}
            </div>
          ))}
          <div className="add-trips">
            <span className="icon">+</span>
          </div>
        </div>
        <div className="left-nav-menu">
          <h2>
            SITA Air Account <Icon className="toggle-arrow" src={iconArrowUp} />
          </h2>
          <ul>
            <li>
              <a>Overview</a>
            </li>
            <li>
              <a>Activity</a>
            </li>
            <li>
              <a>Buy, Gift or Transfer Miles</a>
            </li>
            <li>
              <a>Program Information</a>
            </li>
          </ul>
          <div className="horizontal-line"></div>

          <h2>
            Preferences <Icon className="toggle-arrow" src={iconArrowUp} />
          </h2>
          <ul>
            <li>
              <a>My Profile</a>
            </li>
            <li>
              <a>My Companions</a>
            </li>
            <li>
              <a>Contact Information</a>
            </li>
            <li>
              <a>Payment Methods</a>
            </li>
            <li>
              <a>Credits and Vouchers</a>
            </li>
          </ul>
          <div className="horizontal-line"></div>

          <h2>
            Trips <Icon className="toggle-arrow" src={iconArrowUp} />
          </h2>
          <ul>
            <li>
              <a>Manage Trips</a>
            </li>
            <li>
              <a>Saved Searches</a>
            </li>
          </ul>
          <div className="horizontal-line"></div>

          <h2>
            Settings <Icon className="toggle-arrow" src={iconArrowDown} />
          </h2>
        </div>
      </div>
      <Footer footerMode="light" />
    </Main>
  )

  const pageBoardingPassProcessingCredential = (
    <Main footerMode="dark">
      <Header
        setPageState={setPageState}
        startNewAccount={startNewAccount}
        startLogin={startLogin}
        invitation={invitation}
        connectionPurpose={connectionPurpose}
        setConnectionPurpose={setConnectionPurpose}
        loader={loader}
        loggedIn={false}
      />

      <div className="account-row">
        <h1>Processing credentials</h1>
        <FormContainer>
          <FormWrapper>
            <div className="loader-wrapper">
              <div className="loader"></div>
              <h3 className="text-secondary text-bold">Please wait...</h3>
            </div>
          </FormWrapper>
        </FormContainer>
      </div>
      <Footer footerMode="dark" />
    </Main>
  )

  const pageBoardingPassReview = (
    <Main footerMode="dark">
      <Header
        setPageState={setPageState}
        startNewAccount={startNewAccount}
        userData={userData}
        headerMode="light"
        loggedIn={true}
      />
      <div className="content-row">
        <h1>Your boarding pass is ready</h1>
        {selectedTripData ? (
          <div
            className={
              'boarding-pass-' +
              selectedTripData.frequent_flyer_status.toLowerCase()
            }
          >
            <div className="boarding-pass-logo"></div>
            {dtcData.chip_photo_html && dtcData.chip_photo_html !== '' ? (
              <img
                class="passenger-image"
                src={dtcData.chip_photo_html}
                alt=""
              />
            ) : (
              <img class="passenger-image" src={BarCode} alt="" />
            )}
            <div className="divider-line"></div>
            <span className="pass-label gate">Gate</span>
            <span className="pass-data gate-text">
              {selectedTripData.boarding_gate}
            </span>
            <span className="pass-label passenger">Passenger</span>
            <span className="pass-data passenger-text">
              {dtcData.given_names} {dtcData.family_name}
            </span>
            <span className="pass-label departure-city">
              {selectedTripData.ticket_origin_city}
            </span>
            <span className="pass-data departure-city-code">
              {selectedTripData.ticket_origin}
            </span>
            <div className="plane-icon"></div>
            <span className="pass-label arrival-city">
              {selectedTripData.ticket_destination_city}
            </span>
            <span className="pass-data arrival-city-code">
              {selectedTripData.ticket_destination}
            </span>
            <span className="pass-label departure-date">Date of Departure</span>
            <span className="pass-data departure-date-text">
              {DateTime.fromISO(
                selectedTripData.boarding_departure_date_time
              ).toFormat('MMM dd, yyyy')}
            </span>
            <span className="pass-label boarding-time">Boarding</span>
            <span className="pass-data boarding-time-text">
              {selectedTripData.boarding_date_time.split('T')[1]}
            </span>
            <span className="pass-label departing-time">Departing</span>
            <span className="pass-data departing-time-text">
              {selectedTripData.boarding_departure_date_time.split('T')[1]}
            </span>
            <span className="pass-label flight-number">Flight</span>
            <span className="pass-data flight-number-text">
              {selectedTripData.ticket_flight_number}
            </span>
            <span className="pass-label seat-number">Seat</span>
            <span className="pass-data seat-number-text">
              {selectedTripData.ticket_seat_number}
            </span>
            <span className="pass-label terminal">Terminal</span>
            <span className="pass-data terminal-text">
              {selectedTripData.boarding_gate}
            </span>
            <span className="pass-label zone">Zone</span>
            <span className="pass-data zone-text">
              {selectedTripData.boarding_zone_group}
            </span>
            <span className="pass-label status">Status</span>
            <span className="pass-data status-text">
              {selectedTripData.frequent_flyer_status}
            </span>
          </div>
        ) : (
          ''
        )}
        <BtnPrimaryLarge
          className="uppercase"
          onClick={() => downloadBoardingPass()}
        >
          Download Boarding Pass
        </BtnPrimaryLarge>
      </div>

      <Footer footerMode="dark" />
    </Main>
  )

  const pageBoardingPassSuccess = (
    <Main footerMode="dark">
      <Header
        setPageState={setPageState}
        startNewAccount={startNewAccount}
        userData={userData}
        headerMode="light"
        loggedIn={true}
      />
      <div className="content-row">
        <h1 className="title-text">Download Successful</h1>
        <p className="subtitle-text">
          Your boarding pass credential has been successfully issued to your
          mobile app. Thank you for using SITA Air.
        </p>
        <Footer footerMode="dark" />
        <BtnPrimaryLarge
          className="uppercase"
          onClick={() => setPageState('account-dashboard')}
        >
          Return to Dashboard
        </BtnPrimaryLarge>
      </div>
    </Main>
  )

  const pageErrorLoggedIn = (
    <Main footerMode="dark">
      <Header
        setPageState={setPageState}
        startNewAccount={startNewAccount}
        userData={userData}
        headerMode="light"
        loggedIn={true}
      />
      <div className="content-row">
        <h1 className="title-text">Something went wrong</h1>
        <p className="subtitle-text">
          An error has occurred. Please try again.
        </p>
        <BtnPrimaryLarge onClick={() => setPageState('account-dashboard')}>
          Try Again
        </BtnPrimaryLarge>
      </div>
      <Footer footerMode="dark" />
    </Main>
  )

  return (
    <Frame id="app-frame">
      {pageState === 'home' ? pageHomePage : ''}
      {pageState === 'create-account-form' ? pageCreateAccountForm : ''}
      {pageState === 'create-account-issue' ? pageCreateAccountIssue : ''}
      {pageState === 'create-account-success' ? pageCreateAccountSuccess : ''}
      {pageState === 'error-logged-out' ? pageErrorLoggedOut : ''}
      {pageState === 'account-dashboard' ? pageAccountDashboard : ''}
      {pageState === 'boarding-pass-processing-credential'
        ? pageBoardingPassProcessingCredential
        : ''}
      {pageState === 'boarding-pass-review' ? pageBoardingPassReview : ''}
      {pageState === 'boarding-pass-success' ? pageBoardingPassSuccess : ''}
      {pageState === 'error-logged-in' ? pageErrorLoggedIn : ''}
    </Frame>
  )
}

export default App
