import styled from 'styled-components'

export const Form = styled.form``

export const FormWrapper = styled.div`
  margin: 10px 0;
  padding: 10px;
  min-width: 200px;
  background: ${(props) => props.theme.background_primary};
  background-color: white;
  height: 100%;
  text-align: center;
`

// Full-screen forms
export const FormContainer = styled.div`
  padding: 20px;
  margin: 30px 0;
  min-width: 200px;
  height: 100%;
  border-color: #d3d2c9;
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background: ${(props) => props.theme.background_primary};
  background-color: white;
`
export const Label = styled.label`
  margin-right: 180px;
  width: 10%;
  font-size: 16px;
  text-align: left;
  color: ${(props) => props.theme.text_color};
`
export const InputBox = styled.div`
  margin: 7px 0;
  display: flex;
  flex-direction: column;
  height: 60px;
`

// Modal forms
export const Modal = styled.div`
  overflow: hidden;
  margin: auto;
  padding: 10px;
  max-height: 90vh;
  width: 70%;
  font-size: 12px;
  border: 1px solid #d7d7d7;
  background: white;
`
export const ModalLabel = styled.label`
  font-size: 16px;
  width: 40%;
  margin-right: 20px;
  line-height: 28px;
  font-weight: bold;
`
export const InputFieldModal = styled.input`
  color: ${(props) => props.theme.text_color};
  font-size: 16px;
  width: 100%;
  padding: 4px;
  border: 1px solid #d3d2c9;
`
export const ModalContent = styled.div`
  height: 99%;
  width: 100%;
  padding: 10px 5px;
  overflow-y: auto;
  font-size: 24px;
`

/* Icons */
export const Icon = styled.img`
  width: 17px;
  vertical-align: middle;
`
export const IconArrow = styled.img`
  display: none;
  float: left;
  margin: 0 18px;
  width: 40px;
  vertical-align: middle;
  @media (min-width: 720px) {
    display: block;
  }
`
export const IconAvatar = styled.img`
  width: 17px;
  vertical-align: middle;
`
export const IconHelp = styled.img`
  width: 14px;
  vertical-align: middle;
`
export const IconLanguage = styled.img`
  width: 17px;
  vertical-align: middle;
`
export const IconSearch = styled.img`
  margin-right: 13px;
  width: 17px;
  vertical-align: middle;
`
export const IconVisa = styled.img`
  width: 13px;
  margin-right: 8px;
`

/* Buttons */
export const Btn = styled.button`
  padding: 0 27px;
  height: 28px;

  font-family: 'Oxygen', sans-serif;
  font-size: 12px;
  line-height: 27px;
  font-weight: 700;

  border: none;
  border-radius: 14px;

  :hover {
    cursor: pointer;
  }
`
export const BtnPrimaryLight = styled.button`
  padding: 0 20px;
  height: 28px;

  font-family: 'Oxygen', sans-serif;
  font-size: 12px;
  line-height: 27px;
  font-weight: 700;
  color: #ef7d00;

  border: none;
  border-radius: 14px;
  background-color: #fff;

  :hover {
    cursor: pointer;
  }
`
export const BtnPrimaryLarge = styled.button`
  padding: 0 40px;
  height: 44px;

  font-family: 'Oxygen', sans-serif;
  font-size: 20px;
  line-height: 43px;
  font-weight: 700;
  color: #fff;

  border: none;
  border-radius: 22px;
  background-color: #ef7d00;

  :hover {
    cursor: pointer;
  }
`
export const BtnSecondary = styled.button`
  padding: 0 27px;
  height: 28px;

  font-family: 'Oxygen', sans-serif;
  font-size: 16px;
  line-height: 27px;
  font-weight: 700;
  color: #fff;

  border: none;
  border-radius: 14px;
  background-color: #0069b4;

  :hover {
    cursor: pointer;
  }
`
export const BtnSecondaryLarge = styled.button`
  padding: 0 40px;
  height: 44px;

  font-family: 'Oxygen', sans-serif;
  font-size: 16px;
  line-height: 43px;
  font-weight: bold;
  color: #fff;

  border: none;
  border-radius: 22px;
  background-color: #0069b4;

  :hover {
    cursor: pointer;
  }
`
export const BtnSecondaryLightLarge = styled.button`
  padding: 0 40px;
  height: 44px;

  font-family: 'Oxygen', sans-serif;
  font-size: 20px;
  line-height: 43px;
  font-weight: bold;
  color: #0069b4;

  border: 1px solid #0069b4;
  border-radius: 22px;
  background-color: #fff;

  :hover {
    cursor: pointer;
  }
`
export const BtnTertiaryLightLarge = styled.button`
  padding: 0 40px;
  height: 44px;

  font-family: 'Oxygen', sans-serif;
  font-size: 20px;
  line-height: 44px;
  font-weight: bold;
  color: #222;

  border: none;
  border-radius: 22px;
  background-color: white;

  :hover {
    cursor: pointer;
  }
`
export const BtnTertiaryLightSmall = styled.button`
  margin-right: 6px;
  padding: 0 18px;
  height: 25px;

  font-family: 'Oxygen', sans-serif;
  font-size: 12px;
  line-height: 24px;
  font-weight: bold;
  color: #222;

  border: 1px solid #222;
  border-radius: 13px;
  background-color: white;

  :hover {
    cursor: pointer;
  }
`

/* The only button style that doesn't follow the general scheme, LOL */
export const BtnDashboard = styled.button`
  padding: 0 20px;
  height: 28px;

  font-family: 'Oxygen', sans-serif;
  font-size: 12px;
  line-height: 27px;
  font-weight: bold;
  color: #fff;

  border: 1px #fff solid;
  border-radius: 15px;
  background-color: transparent;

  :hover {
    cursor: pointer;
  }
`

/* Decorative Components */
export const Logo = styled.img`
  float: left;
  width: 139px;
  :hover {
    cursor: pointer;
  }
`
export const PoweredByLogo = styled.img`
  position: static;
  display: block;
  margin: 0 auto 8px;
  text-align: center;

  @media (min-width: 540px) {
    position: absolute;
    width: 140px;
    right: 25px;
    bottom: 20px;
  }
`
export const Bubble = styled.img`
  position: absolute;
  display: none;

  vertical-align: top;
  animation: rotate infinite 10s;

  z-index: 100;
  @media (min-width: 1260px) {
    display: inline;
  }
  @keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
    }
  }
`

/* Header */
export const HeaderOptions = styled.div`
  float: right;
  height: 28px;
  font-size: 12px;
  line-height: 25px;
`

/* Home Page */
export const Thumbnail = styled.img`
  float: left;
  margin-right: 25px;
  width: 40%;
  border-radius: 7px;
`
